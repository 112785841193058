import React from "react"
import { Layout } from "../components/layout"
import Button from "../components/button"
import { Link } from "gatsby"
import styles from "../styles/style"

const RefundPolicy = () => {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="text-gray-700 body-font mt-4 mb-10">
            <div className="container mx-auto flex  md:flex-row flex-col items-center">
              <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-8 md:mb-0">
                <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full  mb-4">
                  <span className="text-green-gradient">Refund Policy</span>
                </h2>
                <p className="mb-4 leading-relaxed">
                  We have a 7-day return policy, which means you have 7 days
                  after receiving your item to request a return.
                </p>
                <p className="mb-4 leading-relaxed">
                  To be eligible for a return, your item must be in the same
                  condition that you received it, unworn or unused, with tags,
                  and in its original packaging. You’ll also need the receipt or
                  proof of purchase.
                </p>
                <p className="mb-4 leading-relaxed">
                  To start a return, you can contact us at
                  returns@hspepper.co.uk If your return is accepted, we’ll send
                  you a return shipping label, as well as instructions on how
                  and where to send your package. Items sent back to us without
                  first requesting a return will not be accepted.
                </p>
                <div className="flex justify-center ">
                  <Link to="/products" alt="h&s pepper products">
                    <Button label="View Products" styles={`mt-2`} />
                  </Link>
                </div>
              </div>
              <div className="lg:max-w-lg lg:w-full md:w-1/2 ">
                <img
                  className="object-cover object-center rounded"
                  alt="hero"
                  src="https://dummyimage.com/720x600/edf2f7/a5afbd"
                />
              </div>
            </div>
          </section>

          <section className="text-gray-700 body-font border-t border-gray-200 mb-12">
            <div className="container mx-auto flex flex-col mt-8">
              <h2 className="text-xl title-font  font-bold mb-3">
                <span className="text-green-gradient">Damages and Issues</span>
              </h2>
              <p className="mb-4 leading-relaxed">
                Please inspect your order upon reception and contact us
                immediately if the item is defective, damaged or if you receive
                the wrong item, so that we can evaluate the issue and make it
                right.
              </p>
              <h2 className="text-xl title-font  font-bold mb-3">
                <span className="text-green-gradient">
                  Non-returnable items
                </span>
              </h2>
              <p className="mb-4 leading-relaxed">
                Unfortunately, we cannot accept returns on gift cards.
              </p>
              <h2 className="text-xl title-font  font-bold mb-3">
                <span className="text-green-gradient">Exchanges</span>
              </h2>
              <p className="mb-4 leading-relaxed">
                The fastest way to ensure you get what you want is to return the
                item you have, and once the return is accepted, make a separate
                purchase for the new item.
              </p>
              <h2 className="text-xl title-font  font-bold mb-3">
                <span className="text-green-gradient">Refunds</span>
              </h2>
              <p className="mb-4 leading-relaxed">
                We will notify you once we’ve received and inspected your
                return, and let you know if the refund was approved or not. If
                approved, you’ll be automatically refunded on your original
                payment method. Please remember it can take some time for your
                bank or credit card company to process and post the refund too.
              </p>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default RefundPolicy
